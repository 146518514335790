import React from 'react';
import GameContainer from './components/GameContainer'

class App extends React.Component {
  render() {
    return (
      <GameContainer />
    );
  }
}

export default App;
